export const translations = {
  de: {
    search: 'Suche starten',
    searching: 'Suche läuft...',
    skip: 'Partner überspringen',
    connected: 'Verbunden mit Partner aus',
    unknown: 'Unbekannt',
    sendMessage: 'Nachricht eingeben...',
    deviceSettings: {
      camera: 'Kamera',
      microphone: 'Mikrofon'
    },
    partnerIsTyping: 'Partner schreibt',
    soundEnabled: 'Ton ein',
    soundDisabled: 'Ton aus',
    connectionQuality: {
      good: 'Gute Verbindung',
      medium: 'Mittelmäßige Verbindung',
      poor: 'Schlechte Verbindung'
    },
    switchToLight: 'Zu Hell wechseln',
    switchToDark: 'Zu Dunkel wechseln',
  },
  en: {
    search: 'Start Search',
    searching: 'Searching...',
    skip: 'Skip Partner',
    connected: 'Connected with partner from',
    unknown: 'Unknown',
    sendMessage: 'Type a message...',
    deviceSettings: {
      camera: 'Camera',
      microphone: 'Microphone'
    },
    partnerIsTyping: 'Partner is typing',
    soundEnabled: 'Sound on',
    soundDisabled: 'Sound off',
    connectionQuality: {
      good: 'Good connection',
      medium: 'Medium connection',
      poor: 'Poor connection'
    },
    switchToLight: 'Switch to Light Mode',
    switchToDark: 'Switch to Dark Mode',
  },
  ja: {
    search: '検索開始',
    searching: '検索中...',
    skip: 'パートナーをスキップ',
    connected: 'パートナーの国',
    unknown: '不明',
    sendMessage: 'メッセージを入力...',
    deviceSettings: {
      camera: 'カメラ',
      microphone: 'マイク'
    },
    partnerIsTyping: 'パートナーが入力中',
    soundEnabled: '音声オン',
    soundDisabled: '音声オフ',
    connectionQuality: {
      good: '良好な接続',
      medium: '普通の接続',
      poor: '不安定な接続'
    },
    switchToLight: 'ライトモードに切り替え',
    switchToDark: 'ダークモードに切り替え',
  },
  zh: {
    search: '开始搜索',
    searching: '搜索中...',
    skip: '跳过伙伴',
    connected: '已连接伙伴来自',
    unknown: '未知',
    sendMessage: '输入消息...',
    deviceSettings: {
      camera: '摄像头',
      microphone: '麦克风'
    },
    partnerIsTyping: '对方正在输入',
    soundEnabled: '声音开启',
    soundDisabled: '声音关闭',
    connectionQuality: {
      good: '连接良好',
      medium: '连接一般',
      poor: '连接不佳'
    },
    switchToLight: '切换到明亮模式',
    switchToDark: '切换到暗黑模式',
  },
  es: {
    search: 'Iniciar búsqueda',
    searching: 'Buscando...',
    skip: 'Saltar pareja',
    connected: 'Conectado con pareja de',
    unknown: 'Desconocido',
    sendMessage: 'Escribe un mensaje...',
    deviceSettings: {
      camera: 'Cámara',
      microphone: 'Micrófono'
    },
    partnerIsTyping: 'El compañero está escribiendo',
    soundEnabled: 'Sonido activado',
    soundDisabled: 'Sonido desactivado',
    connectionQuality: {
      good: 'Buena conexión',
      medium: 'Conexión media',
      poor: 'Conexión pobre'
    },
    switchToLight: 'Cambiar a modo claro',
    switchToDark: 'Cambiar a modo oscuro',
  },
  it: {
    search: 'Avvia ricerca',
    searching: 'Ricerca in corso...',
    skip: 'Salta partner',
    connected: 'Connesso con partner da',
    unknown: 'Sconosciuto',
    sendMessage: 'Scrivi un messaggio...',
    deviceSettings: {
      camera: 'Fotocamera',
      microphone: 'Microfono'
    },
    partnerIsTyping: 'Il partner sta scrivendo',
    soundEnabled: 'Audio attivo',
    soundDisabled: 'Audio disattivato',
    connectionQuality: {
      good: 'Connessione buona',
      medium: 'Connessione media',
      poor: 'Connessione scarsa'
    },
    switchToLight: 'Passa alla modalità chiara',
    switchToDark: 'Passa alla modalità scura',
  }
};

export const countryTranslations = {
  de: {
    'DE': 'Deutschland',
    'AT': 'Österreich',
    'CH': 'Schweiz',
  },
  en: {
    'DE': 'Germany',
    'AT': 'Austria',
    'CH': 'Switzerland',
    'GB': 'Great Britain',
    'FR': 'France',
    'IT': 'Italy',
    'ES': 'Spain',
    'PT': 'Portugal',
    'NL': 'Netherlands',
    'BE': 'Belgium',
    'LU': 'Luxembourg',
    'DK': 'Denmark',
    'SE': 'Sweden',
    'NO': 'Norway',
    'FI': 'Finland',
    'IS': 'Iceland',
    'IE': 'Ireland',
    'PL': 'Poland',
    'CZ': 'Czech Republic',
    'SK': 'Slovakia',
    'HU': 'Hungary',
    'RO': 'Romania',
    'BG': 'Bulgaria',
    'GR': 'Greece',
    'HR': 'Croatia',
    'SI': 'Slovenia',
    'RS': 'Serbia',
    'ME': 'Montenegro',
    'AL': 'Albania',
    'MK': 'North Macedonia',
    'LT': 'Lithuania',
    'LV': 'Latvia',
    'EE': 'Estonia',
    'US': 'United States',
    'CA': 'Canada',
    'MX': 'Mexico',
    'BR': 'Brazil',
    'AR': 'Argentina',
    'CL': 'Chile',
    'CN': 'China',
    'JP': 'Japan',
    'KR': 'South Korea',
  },
  ja: {
    'DE': 'ドイツ',
    'AT': 'オーストリア',
    'CH': 'スイス',
    'GB': 'イギリス',
    'FR': 'フランス',
    'IT': 'イタリア',
    'ES': 'スペイン',
    'PT': 'ポルトガル',
    'NL': 'オランダ',
    'US': 'アメリカ合衆国',
    'CA': 'カナダ',
    'MX': 'メキシコ',
    'BR': 'ブラジル',
    'CN': '中国',
    'JP': '日本',
    'KR': '韓国',
  },
  zh: {
    'DE': '德国',
    'AT': '奥地利',
    'CH': '瑞士',
    'GB': '英国',
    'FR': '法国',
    'IT': '意大利',
    'ES': '西班牙',
    'PT': '葡萄牙',
    'NL': '荷兰',
    'US': '美国',
    'CA': '加拿大',
    'MX': '墨西哥',
    'BR': '巴西',
    'CN': '中国',
    'JP': '日本',
    'KR': '韩国',
  },
  es: {
    'DE': 'Alemania',
    'AT': 'Austria',
    'CH': 'Suiza',
    'GB': 'Gran Bretaña',
    'FR': 'Francia',
    'IT': 'Italia',
    'ES': 'España',
    'PT': 'Portugal',
    'NL': 'Países Bajos',
    'US': 'Estados Unidos',
    'CA': 'Canadá',
    'MX': 'México',
    'BR': 'Brasil',
    'CN': 'China',
    'JP': 'Japón',
    'KR': 'Corea del Sur',
  },
  it: {
    'DE': 'Germania',
    'AT': 'Austria',
    'CH': 'Svizzera',
    'GB': 'Gran Bretagna',
    'FR': 'Francia',
    'IT': 'Italia',
    'ES': 'Spagna',
    'PT': 'Portogallo',
    'NL': 'Paesi Bassi',
    'US': 'Stati Uniti',
    'CA': 'Canada',
    'MX': 'Messico',
    'BR': 'Brasile',
    'CN': 'Cina',
    'JP': 'Giappone',
    'KR': 'Corea del Sud',
  }
}; 