import React, { useState, useEffect, useRef, memo } from 'react';
import io from 'socket.io-client';
import './App.css';
import { translations } from './translations';
import { countryTranslations } from './translations';
import EmojiPicker from 'emoji-picker-react';

// Länder-Mapping für vollständige Namen
const countryNames = {
  // Europa
  'DE': 'Deutschland',
  'AT': 'Österreich',
  'CH': 'Schweiz',
  'GB': 'Großbritannien',
  'FR': 'Frankreich',
  'IT': 'Italien',
  'ES': 'Spanien',
  'PT': 'Portugal',
  'NL': 'Niederlande',
  'BE': 'Belgien',
  'LU': 'Luxemburg',
  'DK': 'Dänemark',
  'SE': 'Schweden',
  'NO': 'Norwegen',
  'FI': 'Finnland',
  'IS': 'Island',
  'IE': 'Irland',
  'PL': 'Polen',
  'CZ': 'Tschechien',
  'SK': 'Slowakei',
  'HU': 'Ungarn',
  'RO': 'Rumänien',
  'BG': 'Bulgarien',
  'GR': 'Griechenland',
  'HR': 'Kroatien',
  'SI': 'Slowenien',
  'RS': 'Serbien',
  'ME': 'Montenegro',
  'AL': 'Albanien',
  'MK': 'Nordmazedonien',
  'LT': 'Litauen',
  'LV': 'Lettland',
  'EE': 'Estland',

  // Amerika
  'US': 'USA',
  'CA': 'Kanada',
  'MX': 'Mexiko',
  'BR': 'Brasilien',
  'AR': 'Argentinien',
  'CL': 'Chile',
  'CO': 'Kolumbien',
  'PE': 'Peru',
  'VE': 'Venezuela',
  'UY': 'Uruguay',
  'PY': 'Paraguay',
  'BO': 'Bolivien',
  'EC': 'Ecuador',

  // Asien
  'CN': 'China',
  'JP': 'Japan',
  'KR': 'Südkorea',
  'IN': 'Indien',
  'ID': 'Indonesien',
  'MY': 'Malaysia',
  'SG': 'Singapur',
  'TH': 'Thailand',
  'VN': 'Vietnam',
  'PH': 'Philippinen',
  'TR': 'Türkei',
  'SA': 'Saudi-Arabien',
  'AE': 'Vereinigte Arabische Emirate',
  'IL': 'Israel',
  'RU': 'Russland',
  'KZ': 'Kasachstan',

  // Afrika
  'ZA': 'Südafrika',
  'EG': 'Ägypten',
  'MA': 'Marokko',
  'TN': 'Tunesien',
  'NG': 'Nigeria',
  'KE': 'Kenia',
  'GH': 'Ghana',
  'ET': 'Äthiopien',

  // Ozeanien
  'AU': 'Australien',
  'NZ': 'Neuseeland',

  // Weitere wichtige Regionen
  'HK': 'Hongkong',
  'TW': 'Taiwan',
  'UA': 'Ukraine',
  'BY': 'Belarus',
  'MD': 'Moldawien',
  'AM': 'Armenien',
  'GE': 'Georgien',
  'AZ': 'Aserbaidschan',
  'KG': 'Kirgisistan',
  'UZ': 'Usbekistan',
  'TJ': 'Tadschikistan',
  'TM': 'Turkmenistan',
  'MN': 'Mongolei',
  'LB': 'Libanon',
  'JO': 'Jordanien',
  'IQ': 'Irak',
  'IR': 'Iran',
  'AF': 'Afghanistan',
  'PK': 'Pakistan',
  'BD': 'Bangladesch',
  'LK': 'Sri Lanka',
  'NP': 'Nepal',
  'BT': 'Bhutan',
  'MM': 'Myanmar',
  'KH': 'Kambodscha',
  'LA': 'Laos'
};

// Hilfsfunktion für Ländernamen
const getCountryName = (countryCode, language = 'de') => {
  if (countryCode === 'Unbekannt') {
    return translations[language].unknown;
  }
  return countryTranslations[language]?.[countryCode] || countryTranslations['en'][countryCode] || countryCode;
};

// Hilfsfunktion für Länderflaggen
const getCountryFlag = (countryCode) => {
  if (countryCode === 'Unbekannt') return '🌍';
  // Konvertiere Ländercode zu regionalen Indikatoren für Emoji-Flaggen
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map(char => 127397 + char.charCodeAt());
  return String.fromCodePoint(...codePoints);
};

// Chat als separate Komponente definieren
const Chat = memo(({ 
  messages, 
  onSendMessage, 
  hasPartner, 
  partnerCountry, 
  t, 
  language,
  socketRef,
  theme
}) => {
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [isPartnerTyping, setIsPartnerTyping] = useState(false);
  const [currentMessage, setCurrentMessage] = useState('');
  const typingTimeoutRef = useRef(null);

  useEffect(() => {
    // Warte auf Socket-Verbindung
    const setupSocketListeners = () => {
      if (socketRef.current) {
        // Event-Listener registrieren
        socketRef.current.on('partner-typing', () => {
          setIsPartnerTyping(true);
        });

        socketRef.current.on('partner-stopped-typing', () => {
          setIsPartnerTyping(false);
        });

        // Cleanup-Funktion
        return () => {
          if (socketRef.current) {
            socketRef.current.off('partner-typing');
            socketRef.current.off('partner-stopped-typing');
          }
        };
      }
    };

    // Initial Setup
    const cleanup = setupSocketListeners();
    
    // Cleanup bei Unmount
    return () => {
      if (cleanup) cleanup();
    };
  }, [socketRef.current]); // Abhängigkeit von socketRef.current

  const handleInputChange = (e) => {
    setCurrentMessage(e.target.value);
    
    if (socketRef.current) {
      socketRef.current.emit('typing');
      
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
      }
      
      typingTimeoutRef.current = setTimeout(() => {
        if (socketRef.current) {
          socketRef.current.emit('stop-typing');
        }
      }, 1000);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (currentMessage.trim() && hasPartner && socketRef.current) {
      onSendMessage(currentMessage);
      setCurrentMessage('');
    }
  };

  const onEmojiClick = (emojiData) => {
    setCurrentMessage(prev => prev + emojiData.emoji);
    setShowEmojiPicker(false);
  };

  return (
    <div className="chat-container">
      <div className="chat-messages">
        {messages.map((msg, index) => (
          <div key={index} className={`message ${msg.self ? 'message-own' : 'message-partner'}`}>
            <div className="message-content">
              {msg.text}
              <span className="message-time">
                {new Date(msg.timestamp).toLocaleTimeString()}
              </span>
            </div>
          </div>
        ))}
        {isPartnerTyping && (
          <div className="typing-indicator">
            <div className="typing-dots">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        )}
      </div>

      <form className="chat-input-container" onSubmit={handleSubmit}>
        <button 
          type="button" 
          className="emoji-button"
          onClick={() => setShowEmojiPicker(!showEmojiPicker)}
          disabled={!hasPartner}
        >
          😊
        </button>
        {showEmojiPicker && (
          <div className="emoji-picker-container" onClick={e => e.stopPropagation()}>
            <EmojiPicker 
              onEmojiClick={onEmojiClick}
              width={280}
              height={350}
              theme={theme === 'dark' ? 'dark' : 'light'}
              searchDisabled
              skinTonesDisabled
              previewConfig={{
                showPreview: false
              }}
            />
          </div>
        )}
        <input
          type="text"
          value={currentMessage}
          onChange={handleInputChange}
          placeholder={t.sendMessage}
          disabled={!hasPartner}
        />
        <button type="submit" disabled={!hasPartner}>
          <i className="fas fa-paper-plane"></i>
        </button>
      </form>
    </div>
  );
});

// Verbindungsqualitäts-Komponente
const ConnectionQuality = memo(({ quality, t }) => {
  const getQualityIcon = () => {
    switch(quality) {
      case 'good': return 'signal';
      case 'medium': return 'signal-2';
      case 'poor': return 'signal-1';
      default: return 'signal-slash';
    }
  };

  return (
    <div className={`connection-quality ${quality}`} title={t.connectionQuality[quality]}>
      <i className={`fas fa-${getQualityIcon()}`}></i>
    </div>
  );
});

// Hauptkomponente
function App() {
  const [connected, setConnected] = useState(false);
  const [searching, setSearching] = useState(false);
  const [hasPartner, setHasPartner] = useState(false);
  
  const socketRef = useRef(null);
  const localVideoRef = useRef();
  const remoteVideoRef = useRef();
  const localStream = useRef();
  const peerConnectionRef = useRef(null);

  const [muted, setMuted] = useState(false);
  const [cameraOff, setCameraOff] = useState(false);
  const [devices, setDevices] = useState({ audio: [], video: [] });
  const [selectedAudioDevice, setSelectedAudioDevice] = useState('');
  const [selectedVideoDevice, setSelectedVideoDevice] = useState('');
  const [showDeviceSelect, setShowDeviceSelect] = useState(false);

  // Basis-Konfiguration für WebRTC
  const configuration = {
    iceServers: [
      { 
        urls: [
          'stun:stun.l.google.com:19302',
          'stun:stun1.l.google.com:19302',
          'stun:stun2.l.google.com:19302'
        ]
      },
      {
        urls: 'turn:ranbit.net:3478',
        username: 'webrtc',
        credential: 'turnserver',
        credentialType: 'password'
      }
    ],
    iceCandidatePoolSize: 10,
    iceTransportPolicy: 'all',
    bundlePolicy: 'balanced'
  };

  // Neue State-Variablen hinzufügen
  const [messages, setMessages] = useState([]);

  // Speichere ICE-Kandidaten, die vor der Remote Description ankommen
  const pendingIceCandidates = useRef([]);

  // WebRTC Peer Connection erstellen
  const createPeerConnection = async () => {
    try {
      if (peerConnectionRef.current) {
        //console.log('Schließe alte Peer Connection');
        peerConnectionRef.current.close();
        peerConnectionRef.current = null;
      }

      //console.log('Erstelle neue PeerConnection');
      const pc = new RTCPeerConnection(configuration);
      peerConnectionRef.current = pc;

      setupPeerConnectionHandlers(pc);

      // Lokale Tracks hinzufügen
      if (localStream.current) {
        //console.log('Füge lokale Tracks hinzu');
        localStream.current.getTracks().forEach(track => {
          //console.log('Füge lokalen Track hinzu:', track.kind);
          pc.addTrack(track, localStream.current);
        });
      } else {
        console.warn('Kein lokaler Stream verfügbar');
      }

      return pc;
    } catch (error) {
      console.error('Fehler beim Erstellen der Peer Connection:', error);
      throw error;
    }
  };

  // Socket-Verbindung im useEffect
  useEffect(() => {
    if (!socketRef.current) {
      socketRef.current = io('https://ranbit.net', {
        path: '/socket.io',
        transports: ['polling'],  // Start with polling only
        reconnectionAttempts: 5,
        reconnectionDelay: 1000,
        timeout: 10000,
        forceNew: true,
        autoConnect: true,
        withCredentials: false,
        secure: true,
        rejectUnauthorized: false,
        extraHeaders: {
          'Access-Control-Allow-Origin': '*'
        }
      });

      // Enhanced error handling
      socketRef.current.on('connect_error', (error) => {
        console.error('Socket connection error details:', {
          message: error.message,
          type: error.type,
          description: error.description
        });
        setConnected(false);
      });

      socketRef.current.on('connect_failed', (error) => {
        console.error('Socket connection failed:', error);
      });

      socketRef.current.on('error', (error) => {
        console.error('Socket general error:', error);
      });

      socketRef.current.on('connect', () => {
        //console.log('Socket connected successfully');
        setConnected(true);
      });

      socketRef.current.on('disconnect', (reason) => {
        console.log('Socket disconnected:', reason);
        setConnected(false);
      });

      // Partner-Found Handler
      socketRef.current.on('partner found', async ({ country }) => {
        //console.log('Partner gefunden aus:', country);
        setPartnerCountry(country);
        setSearching(false);
        setHasPartner(true);
        setMessages([]); // Chat leeren bei neuem Partner
        
        try {
          if (!localStream.current) {
            await initLocalStream();
          }
          // Keine neue PeerConnection erstellen, wenn wir der Initiator sind
          if (!peerConnectionRef.current) {
            await createPeerConnection();
          }
        } catch (error) {
          console.error('Fehler beim Initialisieren der Verbindung:', error);
        }
      });

      // Chat-Message Handler
      socketRef.current.on('chat-message', (message) => {
        //console.log('Chat-Nachricht empfangen:', message);
        if (!message.self) {
          setMessages(prev => {
            //console.log('Aktuelle Nachrichten:', prev);
            //console.log('Neue Nachricht hinzufügen:', message);
            return [...prev, {
              text: message.text,
              timestamp: message.timestamp,
              sender: 'partner'
            }];
          });
        }
      });

      // Debug-Event für alle Socket-Events
      socketRef.current.onAny((event, ...args) => {
        //console.log('Socket Event:', event, args);
      });
    }

    return () => {
      if (socketRef.current) {
        socketRef.current.off('chat-message');
        socketRef.current.off('partner found');
        socketRef.current.disconnect();
      }
    };
  }, []);

  // Funktion zum Senden von Nachrichten
  const sendMessage = (message) => {
    if (socketRef.current && hasPartner) {
      const messageData = {
        text: message,
        timestamp: new Date().toISOString(),
        self: true
      };
      
      //console.log('Sende Nachricht:', messageData);
      socketRef.current.emit('send-message', messageData);
      
      // Füge eigene Nachricht zum Chat hinzu
      setMessages(prev => [...prev, {
        ...messageData,
        sender: 'me'
      }]);
    }
  };

  // Funktion zum Hinzufügen von ICE-Kandidaten
  const addIceCandidate = async (candidate) => {
    const pc = peerConnectionRef.current;
    if (!pc) return;

    try {
      if (pc.remoteDescription && pc.remoteDescription.type) {
        await pc.addIceCandidate(new RTCIceCandidate(candidate));
        //console.log('ICE Candidate hinzugefügt');
      } else {
        //console.log('Speichere ICE Candidate für später');
        pendingIceCandidates.current.push(candidate);
      }
    } catch (err) {
      console.error('Fehler beim Hinzufügen des ICE Candidates:', err);
    }
  };

  // Socket Event Handler für WebRTC
  useEffect(() => {
    if (!socketRef.current) return;

    // Offer Handler
    socketRef.current.on('offer', async (offer) => {
      //console.log('Offer erhalten');
      try {
        const pc = peerConnectionRef.current;
        await pc.setRemoteDescription(new RTCSessionDescription(offer));
        //console.log('Remote Description (Offer) gesetzt');

        // Erstelle und sende Answer
        const answer = await pc.createAnswer();
        await pc.setLocalDescription(answer);
        //console.log('Local Description (Answer) gesetzt');
        socketRef.current.emit('answer', answer);

        // Füge gespeicherte ICE-Kandidaten hinzu
        //console.log('Füge gespeicherte ICE Candidates hinzu');
        for (const candidate of pendingIceCandidates.current) {
          await pc.addIceCandidate(new RTCIceCandidate(candidate));
        }
        pendingIceCandidates.current = [];
      } catch (err) {
        console.error('Fehler beim Verarbeiten des Offers:', err);
      }
    });

    // Answer Handler
    socketRef.current.on('answer', async (answer) => {
      //console.log('Answer erhalten');
      try {
        const pc = peerConnectionRef.current;
        if (!pc) {
          console.error('Keine PeerConnection vorhanden');
          return;
        }
        
        if (pc.signalingState !== 'have-local-offer') {
          console.warn('Unerwarteter Signaling State:', pc.signalingState);
          return;
        }

        await pc.setRemoteDescription(new RTCSessionDescription(answer));
        //console.log('Remote Description (Answer) gesetzt');
        
        // Füge gespeicherte ICE-Kandidaten hinzu
        if (pendingIceCandidates.current.length > 0) {
          //console.log(`Füge ${pendingIceCandidates.current.length} gespeicherte ICE Candidates hinzu`);
          for (const candidate of pendingIceCandidates.current) {
            await pc.addIceCandidate(new RTCIceCandidate(candidate));
            //console.log('Gespeicherter ICE Candidate hinzugefügt');
          }
          pendingIceCandidates.current = [];
        }
      } catch (err) {
        console.error('Fehler beim Verarbeiten der Answer:', err);
      }
    });

    // ICE Candidate Handler
    socketRef.current.on('ice-candidate', async (candidate) => {
      //console.log('ICE Candidate erhalten');
      await addIceCandidate(candidate);
    });

    return () => {
      pendingIceCandidates.current = [];
      if (socketRef.current) {
        socketRef.current.off('offer');
        socketRef.current.off('answer');
        socketRef.current.off('ice-candidate');
      }
    };
  }, []);

  // Peer Connection Event Handler Setup
  const setupPeerConnectionHandlers = (pc) => {
    pc.onicecandidate = ({candidate}) => {
      if (candidate && socketRef.current) {
        //console.log('Sende ICE Candidate:', candidate.type);
        socketRef.current.emit('ice-candidate', candidate);
      }
    };

    pc.ontrack = (event) => {
      //console.log('Remote Track erhalten:', event.track.kind);
      if (remoteVideoRef.current && event.streams && event.streams[0]) {
        //console.log('Setze Remote Stream auf Video Element');
        remoteVideoRef.current.srcObject = event.streams[0];
        
        // Überprüfe, ob das Video tatsächlich abgespielt wird
        remoteVideoRef.current.onloadedmetadata = () => {
          //console.log('Remote Video Metadata geladen');
          remoteVideoRef.current.play()
            //.then(() => console.log('Remote Video Wiedergabe gestartet'))
            .catch(err => console.error('Fehler beim Starten der Wiedergabe:', err));
        };
      } else {
        console.warn('Konnte Remote Stream nicht setzen:', {
          videoElement: !!remoteVideoRef.current,
          hasStreams: !!event.streams,
          streamCount: event.streams?.length
        });
      }
    };

    pc.oniceconnectionstatechange = () => {
      //console.log('ICE Connection State:', pc.iceConnectionState);
      if (pc.iceConnectionState === 'connected') {
        //console.log('ICE Verbindung hergestellt');
      } else if (pc.iceConnectionState === 'failed') {
        console.error('ICE Verbindung fehlgeschlagen');
        // Optionaler Neuversuch
        pc.restartIce();
      }
    };

    pc.onconnectionstatechange = () => {
      //console.log('Connection State:', pc.connectionState);
      if (pc.connectionState === 'connected') {
        //console.log('WebRTC Verbindung erfolgreich hergestellt');
      } else if (pc.connectionState === 'failed') {
        console.error('WebRTC Verbindung fehlgeschlagen');
      }
    };

    pc.onsignalingstatechange = () => {
      //console.log('Signaling State:', pc.signalingState);
    };
  };

  // Initialisiere Kamera und Mikrofon beim Start
  useEffect(() => {
    const initializeMedia = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true
        });
        
        localStream.current = stream;
        if (localVideoRef.current) {
          localVideoRef.current.srcObject = stream;
        }

        // Lade verfügbare Geräte
        const devices = await navigator.mediaDevices.enumerateDevices();
        const audioDevices = devices.filter(device => device.kind === 'audioinput');
        const videoDevices = devices.filter(device => device.kind === 'videoinput');
        
        setDevices({
          audio: audioDevices,
          video: videoDevices
        });

        // Setze Standardgeräte
        if (!selectedAudioDevice && audioDevices.length > 0) {
          setSelectedAudioDevice(audioDevices[0].deviceId);
        }
        if (!selectedVideoDevice && videoDevices.length > 0) {
          setSelectedVideoDevice(videoDevices[0].deviceId);
        }

      } catch (err) {
        console.error('Fehler beim Initialisieren der Mediengeräte:', err);
      }
    };

    initializeMedia();

    // Überwache Geräteänderungen
    const handleDeviceChange = async () => {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const audioDevices = devices.filter(device => device.kind === 'audioinput');
      const videoDevices = devices.filter(device => device.kind === 'videoinput');
      
      setDevices({
        audio: audioDevices,
        video: videoDevices
      });
    };

    navigator.mediaDevices.addEventListener('devicechange', handleDeviceChange);

    // Cleanup
    return () => {
      if (localStream.current) {
        localStream.current.getTracks().forEach(track => track.stop());
      }
      navigator.mediaDevices.removeEventListener('devicechange', handleDeviceChange);
    };
  }, []); // Leere Dependency Array für einmalige Ausführung beim Mount

  // Handler für Geräteänderungen
  const handleDeviceChange = async (type, deviceId) => {
    try {
      if (type === 'audio') {
        setSelectedAudioDevice(deviceId);
      } else {
        setSelectedVideoDevice(deviceId);
      }

      // Stoppe aktuelle Streams
      if (localStream.current) {
        localStream.current.getTracks().forEach(track => track.stop());
      }

      // Starte neue Streams mit ausgewählten Geräten
      const newStream = await navigator.mediaDevices.getUserMedia({
        audio: type === 'audio' ? { deviceId: { exact: deviceId } } : true,
        video: type === 'video' ? { deviceId: { exact: deviceId } } : true
      });

      localStream.current = newStream;
      if (localVideoRef.current) {
        localVideoRef.current.srcObject = newStream;
      }

      // Aktualisiere die Tracks in der PeerConnection
      if (peerConnectionRef.current) {
        const senders = peerConnectionRef.current.getSenders();
        const track = type === 'audio' ? 
          newStream.getAudioTracks()[0] : 
          newStream.getVideoTracks()[0];
        
        const sender = senders.find(s => s.track.kind === track.kind);
        if (sender) {
          sender.replaceTrack(track);
        }
      }

      // Aktualisiere Mute/Camera-Status
      if (type === 'audio') {
        newStream.getAudioTracks().forEach(track => {
          track.enabled = !muted;
        });
      } else {
        newStream.getVideoTracks().forEach(track => {
          track.enabled = !cameraOff;
        });
      }
    } catch (err) {
      console.error('Fehler beim Ändern des Geräts:', err);
    }
  };

  const toggleDeviceSelect = () => {
    setShowDeviceSelect(!showDeviceSelect);
  };

  const initLocalStream = async (videoDeviceId = selectedVideoDevice, audioDeviceId = selectedAudioDevice) => {
    try {
      if (localStream.current) {
        localStream.current.getTracks().forEach(track => track.stop());
      }
      const stream = await navigator.mediaDevices.getUserMedia({
        video: videoDeviceId ? { deviceId: videoDeviceId } : true,
        audio: audioDeviceId ? { deviceId: audioDeviceId } : true
      });
      localStream.current = stream;
      if (localVideoRef.current) {
        localVideoRef.current.srcObject = stream;
      }
      return stream;
    } catch (err) {
      console.error('Fehler beim Initialisieren des lokalen Streams:', err);
      throw err;
    }
  };

  const startSearching = async () => {
    try {
      if (!localStream.current) {
        await initLocalStream();
      }
      
      // Erstelle PeerConnection
      const pc = await createPeerConnection();
      
      // Offer erstellen und setzen
      const offer = await pc.createOffer({
        offerToReceiveAudio: true,
        offerToReceiveVideo: true
      });
      
      await pc.setLocalDescription(offer);
      //console.log('Local Description (Offer) gesetzt');
      
      // Starte die Suche und sende das Offer
      setSearching(true);
      socketRef.current.emit('find partner');
      socketRef.current.emit('offer', offer);
      //console.log('Offer gesendet');
    } catch (err) {
      console.error('Fehler beim Starten der Suche:', err);
      setSearching(false);
    }
  };

  const toggleMute = () => {
    if (localStream.current) {
      const isNowMuted = !muted;
      localStream.current.getAudioTracks().forEach(track => {
        track.enabled = !isNowMuted;
      });
      setMuted(isNowMuted);
      
      // Informiere den Partner über die Änderung
      if (socketRef.current && hasPartner) {
        socketRef.current.emit('device change', {
          type: 'audio',
          enabled: !isNowMuted
        });
      }
    }
  };

  const toggleCamera = () => {
    if (localStream.current) {
      const isNowOff = !cameraOff;
      localStream.current.getVideoTracks().forEach(track => {
        track.enabled = !isNowOff;
      });
      setCameraOff(isNowOff);
      
      // Informiere den Partner über die Änderung
      if (socketRef.current && hasPartner) {
        socketRef.current.emit('device change', {
          type: 'video',
          enabled: !isNowOff
        });
      }
    }
  };

  // Handler für Partner-Disconnect und Skip
  const handlePartnerDisconnect = () => {
    //console.log('Partner-Verbindung wird getrennt');
    setHasPartner(false);
    setSearching(false);
    setMessages([]); // Chat leeren
    
    // WebRTC-Verbindung schließen
    if (peerConnectionRef.current) {
      peerConnectionRef.current.close();
      peerConnectionRef.current = null;
    }
    
    // Remote Video Stream entfernen
    if (remoteVideoRef.current) {
      remoteVideoRef.current.srcObject = null;
    }
  };

  // Socket Event Handler
  useEffect(() => {
    if (!socketRef.current) return;

    socketRef.current.on('partner skipped', handlePartnerDisconnect);
    socketRef.current.on('partner disconnected', handlePartnerDisconnect);

    return () => {
      if (socketRef.current) {
        socketRef.current.off('partner skipped');
        socketRef.current.off('partner disconnected');
      }
    };
  }, []);

  // Skip Partner Funktion
  const skipPartner = () => {
    //console.log('Überspringe aktuellen Partner');
    if (socketRef.current) {
      socketRef.current.emit('skip partner');
      handlePartnerDisconnect(); // Lokale Aufräumarbeiten sofort durchführen
    }
  };

  const [language, setLanguage] = useState(() => localStorage.getItem('language') || 'en');
  const t = translations[language];

  const handleLanguageChange = (newLanguage) => {
    setLanguage(newLanguage);
    localStorage.setItem('language', newLanguage);
  };

  // Definiere LanguageSelector als Komponente innerhalb von App
  const LanguageSelector = () => (
    <select 
      className="language-selector" 
      value={language} 
      onChange={(e) => handleLanguageChange(e.target.value)}
    >
      <option value="de">Deutsch 🇩🇪</option>
      <option value="en">English 🇬🇧</option>
      <option value="ja">日本語 🇯🇵</option>
      <option value="zh">中文 🇳</option>
      <option value="es">Español 🇪🇸</option>
      <option value="it">Italiano ��🇹</option>
    </select>
  );

  const getButtonText = () => {
    if (searching) return t.searching;
    if (hasPartner) return t.skip;
    return t.search;
  };

  const [partnerCountry, setPartnerCountry] = useState('');

  const handleMainButton = () => {
    if (hasPartner) {
      skipPartner();
    } else {
      startSearching();
    }
  };

  const [theme, setTheme] = useState(() => localStorage.getItem('theme') || 'dark');

  useEffect(() => {
    document.body.setAttribute('data-theme', theme);
    localStorage.setItem('theme', theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme(prev => prev === 'dark' ? 'light' : 'dark');
  };

  const [connectionQuality, setConnectionQuality] = useState('good');
  
  useEffect(() => {
    const checkConnectionQuality = () => {
      if (peerConnectionRef.current && peerConnectionRef.current.connectionState === 'connected') {
        peerConnectionRef.current.getStats().then(stats => {
          let packetLoss = 0;
          let roundTripTime = 0;
          
          stats.forEach(report => {
            if (report.type === 'remote-inbound-rtp') {
              packetLoss = report.packetsLost / report.packetsReceived;
            }
            if (report.type === 'candidate-pair' && report.state === 'succeeded') {
              roundTripTime = report.currentRoundTripTime;
            }
          });

          // Bestimme Qualität basierend auf Paketverlusten und Latenz
          let quality = 'good';
          if (packetLoss > 0.1 || roundTripTime > 0.3) {
            quality = 'poor';
          } else if (packetLoss > 0.05 || roundTripTime > 0.15) {
            quality = 'medium';
          }
          
          setConnectionQuality(quality);
        });
      } else {
        setConnectionQuality('poor');
      }
    };

    // Prüfe Qualität alle 5 Sekunden
    const interval = setInterval(checkConnectionQuality, 5000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (peerConnectionRef.current) {
      peerConnectionRef.current.addEventListener('connectionstatechange', () => {
        const state = peerConnectionRef.current.connectionState;
        switch(state) {
          case 'connected':
            setConnectionQuality('good');
            break;
          case 'disconnected':
          case 'failed':
            setConnectionQuality('poor');
            break;
          case 'checking':
            setConnectionQuality('medium');
            break;
          default:
            break;
        }
      });
    }
  }, [peerConnectionRef.current]);

  return (
    <div className={`App ${theme}`}>
      <div className="top-controls">
        <LanguageSelector />
        <button 
          className="theme-toggle"
          onClick={toggleTheme}
          title={theme === 'dark' ? t.switchToLight : t.switchToDark}
        >
          <i className={`fas fa-${theme === 'dark' ? 'sun' : 'moon'}`}></i>
        </button>
      </div>
      <div className="video-chat-container">
        <div className={`video-container ${hasPartner ? 'has-partner' : ''}`}>
          <div className="video-wrapper">
            <video ref={localVideoRef} autoPlay playsInline muted />
            {hasPartner && <ConnectionQuality quality={connectionQuality} t={t} />}
          </div>
          
          <div className="video-wrapper">
            <video ref={remoteVideoRef} autoPlay playsInline />
          </div>

          <div className="controls">
            <button 
              className={`control-button ${muted ? 'active' : ''}`}
              onClick={toggleMute}
            >
              <i className={`fas fa-${muted ? 'microphone-slash' : 'microphone'}`}></i>
            </button>
            
            <button 
              className={`control-button ${cameraOff ? 'active' : ''}`}
              onClick={toggleCamera}
            >
              <i className={`fas fa-${cameraOff ? 'video-slash' : 'video'}`}></i>
            </button>
            
            <button 
              className="control-button"
              onClick={toggleDeviceSelect}
            >
              <i className="fas fa-cog"></i>
            </button>

            <button 
              className={`main-button ${searching ? 'searching' : ''}`}
              onClick={handleMainButton}
              disabled={searching}
            >
              {getButtonText()}
            </button>

            <div className={`device-select ${showDeviceSelect ? 'show' : ''}`}>
              <label>{t.audioInput}</label>
              <select 
                value={selectedAudioDevice} 
                onChange={(e) => handleDeviceChange('audio', e.target.value)}
              >
                {devices.audio.map(device => (
                  <option key={device.deviceId} value={device.deviceId}>
                    {device.label || t.defaultDevice}
                  </option>
                ))}
              </select>

              <label>{t.videoInput}</label>
              <select 
                value={selectedVideoDevice} 
                onChange={(e) => handleDeviceChange('video', e.target.value)}
              >
                {devices.video.map(device => (
                  <option key={device.deviceId} value={device.deviceId}>
                    {device.label || t.defaultDevice}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <Chat 
          messages={messages} 
          onSendMessage={sendMessage} 
          hasPartner={hasPartner} 
          partnerCountry={partnerCountry}
          t={t}
          language={language}
          socketRef={socketRef}
          theme={theme}
        />
      </div>
    </div>
  );
}

export default App; 